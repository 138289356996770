import { useContext, useEffect, useState } from "react";
import Offer from "../../components/offers/Offer";
import { Col, Container, Form, FormControl, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import "./offers.css";

import Skeleton from "../../components/skeletons/Skeleton";
import { OffersContext } from '../../context/offers/OffersContext';
import { TenantContext } from "../../context/tenant/TenantContext";
import { LanguageContext } from "../../context/language/LanguageContext";
import { useRef } from "react";
import { ClientContext } from "../../context/client/ClientContext";
import { Link, useParams } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import { CategoriesContext } from "../../context/categories/CategoriesContext";


export default function Offers() {
    const { client } = useContext(ClientContext)
    const { tenant } = useContext(TenantContext);
    const { offers } = useContext(OffersContext);
    const { categories } = useContext(CategoriesContext);

    const [offerslist, setOffersList] = useState([]);
    const { language } = useContext(LanguageContext);
    const cboxFilter = useRef();

    const [loader, setLoader] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [message, setMessage] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const params = useParams()

    const closeMessageModal = () => {
        setShowMessageModal(false);
    }

    const orderOffers = (off) => {

        let orderby = cboxFilter.current.value; //event.target.value;
        let filteredOffers = off && Array.isArray(off) ? [...off] : [...offerslist];
        if (orderby === "latest") {
            // console.log("latest")

            filteredOffers.sort((a, b) => {
                const aPublished = new Date(a.published);
                const bPublished = new Date(b.published);

                return bPublished.getTime() - aPublished.getTime();
            });
        } else if (orderby === "endingsoon") {
            // console.log("ending soon")
            filteredOffers.sort((a, b) => {
                // console.log(a)
                // console.log(b)
                const aValidTo = (a.validToUTC) ? new Date(a.validToUTC).getTime() : new Date().setDate(100000);
                const bValidTo = (b.validToUTC) ? new Date(b.validToUTC).getTime() : new Date().setDate(100000);
                // console.log(aValidTo, " - ", bValidTo)
                return aValidTo - bValidTo;
            });
        } else if (orderby === "popularity") {
            // console.log("popular")

            filteredOffers.sort((a, b) => {
                let aRedemptions = a.totalRedemptions || 0;
                let bRedemptions = b.totalRedemptions || 0;

                return bRedemptions - aRedemptions;
            });
        }

        setOffersList(filteredOffers);
    }

    const filterOffers = (event) => {
        let filterby = event.target.value;
        var filteredOffers = [];
        for (var i = 0, j = offers; i < j.length; i++) {

            if (j[i].title.toLowerCase().includes(filterby.toLowerCase()) ||
                j[i].mName.toLowerCase().includes(filterby.toLowerCase())) {
                filteredOffers.push(j[i]);
            }
        }
        setOffersList(filteredOffers);
    }

    const setCategoriesList = (category) => {
        // console.log(category)
        let cats = [...categoryList];

        const boxes = document.querySelectorAll('.category');

        boxes.forEach(box => {
            box.classList.remove('catactive');


        });

        if (category === "all") {
            cats = [];
        } else {

            if (cats.indexOf(category) > -1) {

                cats.splice(cats.indexOf(category), 1);

            } else {

                cats.push(category)

            }
        }


        if (cats.length === 0) {
            const catItem = document.getElementById("catall")
            catItem.classList.add("catactive");
        } else {
            cats.map((thiscat, index) => {

                const catItem = document.getElementById(`cat${thiscat.split("/").join("").split(" ").join("")}`)
                catItem.classList.add("catactive");
            })
        }

        setCategoryList(cats);
        categoryFilter(cats)
    }

    const categoryFilter = (cats) => {


        //let filterby = event.target.value;
        var filteredOffers = [];

        let ordersOffers = [...offers];
        if (cats.length > 0) {


            for (var i = 0, j = ordersOffers; i < j.length; i++) {

                if (cats.indexOf(j[i].merchant.category.name) > -1) {

                    filteredOffers.push(j[i]);
                }
                // if (j[i].category.toLowerCase().includes(filterby.toLowerCase()) ||
                //     j[i].mName.toLowerCase().includes(filterby.toLowerCase())) {
                //     filteredOffers.push(j[i]);
                // }
            }
        } else {
            filteredOffers = [...ordersOffers];
        }


        setOffersList(filteredOffers);
        orderOffers(filteredOffers);
    }


    useEffect(() => {

        if (offers && offers.length > 0) {
            setCategoriesList("all");
            // setOffersList(offers);
            //  orderOffers();
        }
    }, [offers]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!params.searchtext) {
            document.getElementById("txtGlobalSearch").value = "";
        }
    }, [])

    return <div>
        <Banner />
        <section className="brand_area pt-4 pt-md-5 pb-50 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.5s">
            <div className="container">
                <div className="section_title pb-4 pb-md-5">
                    <h4 className="sub_title">Find more of what you love</h4>
                </div>

                <div className="catmaincontainer">
                    <div className="categoryContainer">
                        <div id="catall" className="d-inline-block category" onClick={() => { setCategoriesList("all") }}>
                            <div className="grid-icon mb-2"><img src={`${process.env.REACT_APP_CLOUDFRONT}categories/${params.tenantpath}/All.svg`}
                                style={{ borderColor: tenant?.tenantmasterconfig.theme.primary_border_color }} /></div>
                            <div className="grid-label">All</div>
                        </div>
                        {
                            (categories) ?
                                categories?.map((cat, index) => {
                                    return <div id={`cat${cat.split("/").join("").split(" ").join("")}`} className="d-inline-block category" key={index} onClick={() => { setCategoriesList(cat) }}>
                                        <div className="grid-icon mb-2"><img src={`${process.env.REACT_APP_CLOUDFRONT}categories/${params.tenantpath}/${cat.split("/").join("")}.svg`}
                                            style={{ borderColor: tenant?.tenantmasterconfig.theme.primary_border_color }} /></div>
                                        <div className="grid-label">{cat}</div>
                                    </div>
                                })
                                :
                                <Skeleton type="category" count="7" />

                        }

                    </div>
                </div>
                <div className="mt-2">
                    {
                        categoryList?.map((catName, index) => {
                            return <span class="badge text-bg-secondary me-2 p-1" key={index}>
                                {catName}
                                <button type="button" class="btn-close ms-2 text-white" aria-label="Close"
                                    style={{ verticalAlign: "middle", fontSize: "10px" }} onClick={() => { setCategoriesList(catName) }}></button>
                            </span>
                        })

                    }
                </div>
            </div>
        </section>

        <section className="features_area">
            <div className="container">
                <div className="section_title pb-30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.5s">
                    <h4 className="sub_title d-inline-block">Offers</h4>
                    <div className=" d-inline-block" style={{ float: "right" }}>

                        <div className="selector">
                            <select ref={cboxFilter} className="form-select" aria-label="Default select example"
                                onChange={orderOffers} style={{ width: "125px" }}>
                                <option selected value="popularity">Popular</option>
                                <option value="latest">Latest</option>
                                <option value="endingsoon">Ending Soon</option>
                            </select>
                        </div>
                        <label style={{ float: "right", lineHeight: "28px", fontSize: "14px" }} className="d-none d-md-inline-block me-2">Offers sort by:</label>

                    </div>
                </div>

                <div className="row g-4" data-wow-duration="2s" data-wow-delay="0.5s">
                    {
                        (offerslist.length > 0) ?
                            offerslist?.map((offer) => {
                                return <Offer offer={offer} key={offer.id} />
                            }) :
                            <Skeleton type="offer" count="8" />
                    }

                </div>
            </div>
        </section>
    </div>
}
