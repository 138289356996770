
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";


import "./styles/default.css";
import "./styles/font-awesome.min.css";
import "./styles/lineicons.css";
import "./styles/slick-theme.css";
import "./styles/slick.css";
import "./styles/style.css";
import "./styles/animate.css";
import "./styles/style.css.map";
import "./fonts/sfprotext.css";
import "./fonts/stylesheet.css";





import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import PageNotFound from './pages/static/PageNotFound';
import OfferWallLayout from './pages/layouts/OfferWallLayout';
import Unsubscribe from './pages/static/Unsubscribe';
import AWS from './pages/aws/aws';
import { useEffect } from 'react';





function App() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path={"*"} element={<PageNotFound />} />
        <Route path={"/:tenantpath/unsubscribe/:euid/:distributorid"} element={<Unsubscribe />} />

        <Route path={"/:tenantpath/*"} element={<OfferWallLayout />} />
        <Route path={"/aws/*"} element={<AWS />} />
      </Routes>

    </>

  );
}


// function App() {
//   return (
//     <>

//       <TenantHeader />
//       <main className='pb-5 mb-5'>
//         <Routes>
//           <Route path={"/"} element={<PageNotFound />} />

//           <Route path={"/:tenantpath/"} element={<Offers />} />
//           <Route path={"/:tenantpath/signup"} element={<SignUp />} />
//           <Route path={"/:tenantpath/signin"} element={<SignIn />} />
//           <Route path={"/:tenantpath/offerdetail/*"} element={<OfferDetails />} />
//           <Route path={"/:tenantpath/terms"} element={<TenantTerms />} />
//           <Route path={"/:tenantpath/profile"} element={<Profile />} />

//           <Route path={"/:tenantpath/verify/*"} element={<Verify />} />
//           <Route path={"/:tenantpath/auth"} element={<Session />} />
//           <Route path={"/verifycardenrollment/"} element={<VerifyCardEnrollment />} />
//         </Routes>
//       </main>
//     </>
//   );
// }

export default App;
