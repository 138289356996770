import { useContext, useEffect, useState } from "react"
import { TenantContext } from "../../context/tenant/TenantContext"

import "./banner.css";
import DOMPurify from "dompurify";

export default function Banner() {
    const { tenant } = useContext(TenantContext);
    const [bannerUrl, setBannerURL] = useState("");
    const [isMobile, setisMobile] = useState(false);

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // alert(obj.parenttype === "page")
            // console.log("MOBILE")
            setisMobile(true)
            setBannerURL(`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantcontentconfig.banner.dynamic.mobile.image}`)
        } else {
            // console.log("WEB")
            setisMobile(false)
            setBannerURL(`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantcontentconfig.banner.dynamic.web.image}`)
        }
    }

    useEffect(() => {
        // if (obj.offers) window.addEventListener('resize', resizeWindow)
        if (tenant) resizeWindow();

    }, []);

    useEffect(() => {
        // if (obj.offers) window.addEventListener('resize', resizeWindow)
        if (tenant) {
            resizeWindow();
            window.addEventListener("resize", (event) => {
                resizeWindow()
            });
        }
    }, [tenant]);


    return <div id="home" className="header_slider">
        {
            // JSON.stringify(tenant?.tenantcontentconfig)
        }
        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {
                    (tenant?.tenantmasterconfig.banner.type === "static") ?
                        <div className="carousel-item2 active">
                            <img src={`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantcontentconfig.banner.static.image_web}`} className="d-none d-md-block w-100" alt="" />
                            <img src={`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantcontentconfig.banner.static.image_mobile}`} className="d-block d-md-none w-100" alt="" />

                        </div>
                        :
                        <div className="carousel-item active" style={{ backgroundImage: `url(${bannerUrl})` }}>
                            {/* <img src={`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantbannerimage}`} className="d-none d-md-block w-100" alt="..." /> */}
                            {/* <img src={`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantbannerimage.split("/")[0]}/mob_${tenant?.tenantbannerimage.split("/")[1]}`} className="d-block d-md-none w-100" alt="..." /> */}

                            <div className="container h-100">

                                <div className=" h-100 banner-text-container" >
                                    <h1 className="banner-text text-white fadeInUpBig banner-heading fw-bold"
                                        style={{
                                            animationDuration: "1s",
                                            animationDelay: "1s",
                                            animationName: "fadeInUpBig",
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: (isMobile) ?
                                                DOMPurify.sanitize(tenant?.tenantcontentconfig.banner.dynamic.mobile.header_text)
                                                :
                                                DOMPurify.sanitize(tenant?.tenantcontentconfig.banner.dynamic.web.header_text)
                                        }}></h1>

                                    <p class="text-white banner-desc fadeInUpBig" style={{
                                        animationDuration: "1s",
                                        animationDelay: "1s",
                                        animationName: "fadeInUpBig",
                                    }}
                                        dangerouslySetInnerHTML={{
                                            __html: (isMobile) ?
                                                DOMPurify.sanitize(tenant?.tenantcontentconfig.banner.dynamic.mobile.desc_text)
                                                :
                                                DOMPurify.sanitize(tenant?.tenantcontentconfig.banner.dynamic.web.desc_text)
                                        }}></p>




                                    {/* <button className="btn btn-primary ow-btn-primary pt-2 pb-2 p-3 pt-md-3 pb-md-3 p-md-4 mt-3 fadeInUpBig main-btn"
                                    style={{
                                        animationDuration: "1s",
                                        animationDelay: "1s",
                                        animationName: "fadeInUpBig"
                                    }}>Get personalised offers now</button>  */}
                                </div>
                            </div>
                        </div>
                }

            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button> */}
        </div>
    </div>
}