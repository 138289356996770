
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Image, Dropdown, Form, Spinner } from "react-bootstrap";
import { TenantContext } from "../../context/tenant/TenantContext";
import { ClientContext } from "../../context/client/ClientContext";
import { CardContext } from "../../context/card/CardContext";

// import DOMPurify from "dompurify";
import "./tenantfooter.css";
import Skeleton from "../skeletons/Skeleton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { offerwallapi } from "../../config/axiosconfig";
import { LanguageContext } from "../../context/language/LanguageContext";
import { OffersContext } from "../../context/offers/OffersContext";
import { CheckAuth } from "../../utils/auth";
import Cookies from "universal-cookie";

export default function TenantFooter() {
    const { tenant } = useContext(TenantContext);

    const params = useParams()
    const tenanturl = params.tenantpath;

    return <footer id="footer" className="footer_area">
        <div className="container">
            <div className="footer_wrapper pt-50 pb-100">
                <div className="footer-content">
                    <div className="footerLinks">
                        <div className="footer-linkstitle">Support</div>
                        <div className="footerLink">
                            <ul className="footer-ul">
                                {
                                    tenant?.tenantcontentconfig.footer.support.map((link) => {
                                        return <li><a href={link.url} target="_blank">{link.name}</a></li>

                                    })
                                }
                                {/* <li><a href="#">FAQ</a></li> */}
                                {/* <li><a href="https://www.suncorpbank.com.au/bank-and-save/feel-good-rewards" target="_blank">Learn more about Feel Good Rewards</a></li> */}
                                {/* <li><a href="#">Contact Us</a></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="footerLinks">
                        <div className="footer-linkstitle">Legal + Privacy</div>
                        <div className="footerLink">
                            <ul className="footer-ul">
                                <li><Link to={`/${tenanturl}/terms`}>Terms & Conditions</Link></li>
                                {/* <li><a href="#">Cookie Policy</a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="copyrights">© Copyright 1996 - 2022. All Rights Reserved.</div> */}
            </div>
            {
                (tenant?.tenantmasterconfig.footer.show_disclaimer) ?
                    <p className="small" style={{ fontSize: "12px" }}>{tenant?.tenantcontentconfig.footer.disclaimer}</p>
                    : ""
            }
            <p className="small" style={{ fontSize: "12px" }}>
                The SUNCORP brand and Sun Logo are used by Suncorp Bank (Norfina Limited) under licence and Suncorp Bank is not part of the Suncorp Group.
            </p>

        </div>
    </footer>
}


