
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Image, Dropdown, Form, Spinner } from "react-bootstrap";
import { TenantContext } from "../../context/tenant/TenantContext";
import { ClientContext } from "../../context/client/ClientContext";
import { CardContext } from "../../context/card/CardContext";

// import DOMPurify from "dompurify";
import "./tenantheader.css";
import Skeleton from "../skeletons/Skeleton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { offerwallapi } from "../../config/axiosconfig";
import { LanguageContext } from "../../context/language/LanguageContext";
import { OffersContext } from "../../context/offers/OffersContext";
import { CheckAuth } from "../../utils/auth";
import Cookies from "universal-cookie";
import { CategoriesContext } from "../../context/categories/CategoriesContext";

export default function TenantHeader() {

    const navigate = useNavigate();
    const url = useLocation().pathname.split("/");
    const urlPath = url[2];
    const page = url[3];
    const params = useParams()
    const tenanturl = params.tenantpath;
    const { tenant, tenantdispatch } = useContext(TenantContext);
    const { client, clientdispatch } = useContext(ClientContext);
    const { language, languagedispatch } = useContext(LanguageContext);
    const { card, carddispatch } = useContext(CardContext);
    const [languages, setLanguages] = useState([]);
    const [myCards, setMyCards] = useState([]);
    const [defaultCard, setDefaultCard] = useState("");
    const { offersdispatch } = useContext(OffersContext);
    const { categoriesdispatch } = useContext(CategoriesContext);

    const cboxMyCards = useRef();
    const [loader, setLoader] = useState(false);

    const cookies = new Cookies();

    useEffect(() => {

        // if (tenant) getLanguages();

        // if (localStorage.getItem("l")) {
        //     const lang = JSON.parse(localStorage.getItem("l"))
        //     if (lang.languageid === 490) {
        //         localStorage.removeItem("l")
        //     }
        // }

        const { languageid, languagecode } = { languageid: 489, languagecode: "en", languagename: "English" }

        // const { languageid, languagecode } = (localStorage.getItem("l")) ? JSON.parse(localStorage.getItem("l")) : { languageid: 489, languagecode: "en", languagename: "English" }


        getLanguage(languageid, languagecode);


    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tenant) {
            getLanguages();

            setInterval(() => {
                if (localStorage.getItem("c")) {
                    if (!cookies.get("ow-c")) {
                        // cookies.get"ow-c")
                        localStorage.removeItem("c")
                        setTimeout(() => {
                            window.location.replace("/" + tenant.tenanturlname + "/auth")
                        }, 2000)
                    }
                }
            }, 2000)

        }
    }, [tenant]);

    const getLanguages = async () => {
        try {

            const res = await offerwallapi.get("/api/public/tenantmultilingual/" + tenant.tenantid);
            setLanguages(res.data);
        } catch (err) {

        }
    }


    const getTenant = async (lanid) => {


        tenantdispatch({ type: "TENANT_START" });
        try {
            const res = await offerwallapi.get("/api/tenants/tenantgetbyurlname", {
                params: {
                    tenanturlname: tenanturl,
                    languageid: lanid
                }
            });
            if (res.data.length === 0) navigate("/");

            const tenantobj = res.data


            await localStorage.setItem("l", JSON.stringify({ languageid: tenantobj.languageid, languagecode: tenantobj.languagecode }));

            res.data.tenantmasterconfig = JSON.parse(res.data.tenantmasterconfig)
            res.data.tenantcontentconfig = JSON.parse(res.data.tenantcontentconfig)


            tenantdispatch({ type: "TENANT_SUCCESS", payload: res.data });
            document.title = res.data.tenantmasterconfig.title;
            return { languageid: tenantobj.languageid, languagecode: tenantobj.languagecode }

        } catch (err) {
            tenantdispatch({ type: "TENANT_FAIL", payload: err });
        }
    }

    // useEffect(() => {
    //     if (language && tenant) getMyCards();
    // }, [language, tenant]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (client && tenant && language) {
            setTimeout(() => getMyCards(), 1500)
        } else if (tenant && language) {
            getOffers();
        }
    }, [client, tenant, language]);

    const getMyCards = async () => {
        try {
            carddispatch({ type: "CARD_START" });
            const cards = await offerwallapi.get("/api/cards/cardgetall", {
                params: {
                    clientid: client.clientid,
                    tenantid: tenant.tenantid
                }
            });
            carddispatch({ type: "CARD_SUCCESS", payload: cards.data });
            getOffers();
        } catch (err) {
            carddispatch({ type: "CARD_FAIL", payload: err });
        }
    }

    const getOffers = async (lancode) => {


        offersdispatch({ type: "OFFERS_START" });
        try {
            const res = await offerwallapi.get("/api/offers", {
                params: {
                    tenantid: tenant.tenantid,
                    languagecode: (language) ? language.languagecode : "en",
                    cardnumber: "",//(cboxMyCards.current) ? cboxMyCards.current.value : "",
                }
            });
            //res.data.tenantid = tenant.tenantid;
            // offersdispatch({ type: "OFFERS_START" });
            const catList = [];
            for (const item of res.data.offers) {
                if (catList.indexOf(item.merchant.category.name) === -1) {

                    catList.push(item.merchant.category.name)
                }
            }
            // console.log("cats", catList)
            categoriesdispatch({ type: "CATEGORIES_SUCCESS", payload: catList })
            offersdispatch({ type: "OFFERS_SUCCESS", payload: res.data.offers });
        } catch (err) {
            offersdispatch({ type: "OFFERS_FAIL", payload: err });
        }
    }

    const getLanguage = async (lanid, lancode) => {


        const { languageid, languagecode } = await getTenant(lanid);
        try {
            languagedispatch({ type: "LANGUAGE_START" });
            const res = await offerwallapi.get("/api/public/multilingual/" + languageid);

            languagedispatch({ type: "LANGUAGE_SUCCESS", payload: JSON.parse(res.data.languagecontent) });
        } catch (err) {
            languagedispatch({ type: "LANGUAGE_FAIL", payload: err });
        }
        if (client && client.defaultlanguageid !== languageid) updateClientLanguage(languageid, languagecode);
    }


    const clientSignout = async () => {
        // alert(tenant.tenanturlname)
        setLoader(true);
        await offerwallapi.post("/api/clients/clientsignout", {
            clientid: client.clientid,
            tenantid: tenant.tenantid
        });
        clientdispatch({ type: "CLIENT_SIGNOUT" });
        getOffers();

        setTimeout(function () {
            navigate(`/${tenant.tenanturlname}`);
            setLoader(false);
        }, 3000);
    }

    const updateClientLanguage = async (lanid, lancode) => {
        try {
            const res = await offerwallapi.put("/api/clients/clientlanguageupdate", {
                tenantid: tenant.tenantid,
                clientid: client.clientid,
                languageid: lanid
            });
            const clientobj = client
            clientobj.defaultlanguageid = lanid;
            clientobj.languagecode = lancode;
            clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });
        } catch (err) {

        }
    }


    useEffect(() => {
        const inp = document.getElementById("txtGlobalSearch");
        inp.addEventListener("keydown", function (e) {
            if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                globalSearch()
            }
        });


        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${process.env.REACT_APP_CLOUDFRONT}favicons/${tenanturl}.ico`;

    }, [])

    const globalSearch = () => {
        // alert("A")
        const searchparam = document.getElementById("txtGlobalSearch").value;
        if (searchparam !== "")
            navigate(`/${tenanturl}/search/${searchparam}`);
    }


    return <header className="header_area sticky-top">
        <div className="header_navbar" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg pb-4 pt-4">
                    <button class="navbar-toggler me-2 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <Link className="navbar-brand mr-auto" to={`/${tenanturl}`}>
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}${tenant?.tenantmasterconfig.logo}`} alt="Logo" />
                    </Link>

                    <div id="divSearchContainer" className="input-group col" style={{ width: "240px", maxWidth: "240px" }}>
                        <span className="input-group-text bg-none" id="basic-addon1">
                            <i className="bi bi-search"></i>
                        </span>
                        <input type="text" id="txtGlobalSearch" className="form-control" placeholder="Search"
                            aria-label="Search" aria-describedby="basic-addon1" />
                    </div>
                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                        <ul id="nav" className="navbar-nav mr-auto">
                            {/* <li className="nav-item">
                                <Link to={`/${tenanturl}`} className="text-decoration-none">FAQ</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={`/${tenanturl}/terms`} className="text-decoration-none">Terms and Conditions</Link>
                            </li>

                        </ul>
                    </div>


                </nav>
            </div>
        </div>


    </header>


}



