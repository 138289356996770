import { createContext, useEffect, useReducer } from "react"
import ClientReducer from "./ClientReducer";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const CLIENT_INITIAL_STATE = {
    //client: JSON.parse(localStorage.getItem("c")) || null,
    client: cookies.get("ow-c") || null,
    clientIsFetching: false,
    clientError: false
}

export const ClientContext = createContext(CLIENT_INITIAL_STATE);

export const ClientContextProvider = ({ children }) => {
    const [clientstate, clientdispatch] = useReducer(ClientReducer, CLIENT_INITIAL_STATE);

    useEffect(() => {
        // console.log("COOKIE CLIENT : ",  cookies.get("ow-c"))
        // console.log("COOKIE CLIENT 2  : ",  clientstate.client)

        if (clientstate.client) {
            localStorage.setItem("c", JSON.stringify(clientstate.client));
            // cookies.set("ow-c", clientstate.client, {
            //     path: "/",
            //     expires: new Date(new Date().getTime() + 15 * 60 * 1000),
            //     domain: clientstate.client.domain,
            //     sameSite: "none",
            //     secure: true
            // })
             
        } else {
            // console.log("REMOVE CLIENT")
            localStorage.removeItem("c");
            cookies.remove("ow-c") 
        }
    }, [clientstate.client]);


    return <ClientContext.Provider value={{
        client: clientstate.client,
        clientIsFetching: clientstate.clientIsFetching,
        clientError: clientstate.clientError,
        clientdispatch
    }}>{children}</ClientContext.Provider>

}