
import { Route, Routes, useLocation } from "react-router-dom";
import TenantHeader from "../../components/tenant/TenantHeader";
import Session from "../auth/Session";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import Verify from "../auth/Verify";
import OfferDetails from "../offers/OfferDetails";
import Offers from "../offers/Offers";
import Profile from "../profile/Profile";
import TenantTerms from "../termsandconditions/TenantTerms";
import { IdleTimerProvider } from 'react-idle-timer'
import { offerwallauthapi } from '../../config/axiosconfig';
import Cookies from "universal-cookie";


import "./offerwalllayout.css";
import TenantFooter from "../../components/tenant/TenantFooter";
import OfferSearch from "../offers/Search";
import { useEffect } from "react";



export default function OfferWallLayout() {

    const cookies = new Cookies();
    var intervalID = null;
    const { pathname } = useLocation();


    useEffect(() => {
        const page = document.getElementById("divPage");
        const footer = document.getElementById("footer");
        // console.log(page , footer)
        if (page && footer) {
            const footerHeight = footer.offsetHeight;
            const offsetTop = footer.offsetTop;
            const windowHeight = window.innerHeight;
            //console.log("short", offsetTop, windowHeight, footerHeight)
            if (offsetTop < windowHeight - footerHeight) {
                //console.log("SHORT")
                page.style.minHeight = (windowHeight - footerHeight - page.offsetTop - 48 ) + "px"
            } else {
                //console.log("LONG")


            }
        }
    }, [pathname]);



    const intervalManager = (activate) => {
        if (activate) {
            refreshToken()
            intervalID = setInterval(refreshToken, 1000 * 30);
        } else {
            clearInterval(intervalID);
            intervalID = null
        }
    }


    const refreshToken = async () => {
        if (cookies.get("rtid")) {
            try {
                const res = await offerwallauthapi.post("/clients/clientrefresh");
                return res.data;
            } catch (err) { }
        }
    };


    const onIdle = (event) => {
        intervalManager(false)
    }
    // const onActive = (event) => {
    //     if (cookies.get("rtid")) {
    //         refreshToken();
    //     }
    // }
    const onAction = (event) => {
        if (cookies.get("rtid") && !intervalID) {
            intervalManager(true)
        }
    }

    return <IdleTimerProvider
        timeout={1000 * 5}
        //     onPrompt={onPrompt}
        onIdle={onIdle}
        //onActive={onActive}
        onAction={onAction}
        //eventsThrottle={2000}
        //syncTimers={true}
        crossTab={true}>
        <>
            <TenantHeader />
            <main id="divPage" className='pb-5 mb-5'>
                <Routes>
                    <Route path={"/"} element={<Offers />} />
                    <Route path={"/signup"} element={<SignUp />} />
                    <Route path={"/signin"} element={<SignIn />} />
                    <Route path={"/offerdetail/:offerid"} element={<OfferDetails />} />
                    <Route path={"/search/:searchtext"} element={<OfferSearch />} />
                    <Route path={"/merchant/:merchantname"} element={<OfferSearch />} />
                    <Route path={"/offers"} element={<OfferSearch />} />

                    <Route path={"/terms"} element={<TenantTerms />} />
                    <Route path={"/profile"} element={<Profile />} />

                    <Route path={"/verify/*"} element={<Verify />} />
                    <Route path={"/auth"} element={<Session />} />
                </Routes>
            </main>
            <footer id="divFooter">
                <TenantFooter />
            </footer>
        </>
    </IdleTimerProvider>;
}

