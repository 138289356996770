import { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";

import { offerwallapi } from "../../config/axiosconfig";
import { ClientContext } from "../../context/client/ClientContext";
import { TenantContext } from "../../context/tenant/TenantContext";
import CryptoJS from "crypto-js";
import { LanguageContext } from "../../context/language/LanguageContext";
// import { encode, encodeURL } from 'js-base64';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { CheckAuth } from "../../utils/auth";
import { CardContext } from "../../context/card/CardContext";

export default function Profile() {
    const navigate = useNavigate();
    const { tenant } = useContext(TenantContext);
    const { client, clientdispatch } = useContext(ClientContext);
    const { language } = useContext(LanguageContext);
    const { card, carddispatch } = useContext(CardContext);


    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showEmailSubscriptionConfirmModal, setShowEmailSubscriptionConfirmModal] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmType, setConfirmType] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    // const [visaParams, setVisaParams] = useState({});
    const [rsaEncryptedVals, setRSAEncryptedVals] = useState();

    const [cardList, setCardList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedCard, setSelectedCard] = useState({});
    const [eventExists, setEventExist] = useState(false);
    const [expressURL, setExpressURL] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    // const txtVisaCardNumber = useRef();
    const ifrmMini = useRef();
    const chkEmailSubscription = useRef();



    useEffect(() => {
        if (tenant && client) {
            getEmailSubscription()

            window.addEventListener("message", CardAddProcess, false);
            return () => {
                window.removeEventListener('message', CardAddProcess);
            };

          
        }
    }, [tenant, client]);

    const CardAddProcess = async (event) => {
        if (typeof (event.data) === "string" && event.data.includes("/?info=")) {
            setMessage("");
            setLoader(true);
            // console.log("CARD ADD PROCESS")
            const encryptparams = await GetVisaParams();

            const info = `{EUID:${client.externaluserid},PC:${tenant.tenantpromocode},EM:${encryptparams.email}}`;

            const key = CryptoJS.enc.Utf8.parse(encryptparams.visa_key);
            const iv = CryptoJS.enc.Utf8.parse(encryptparams.visa_iv);

            const encryptedInfo = CryptoJS.AES.encrypt(info, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }).toString();

            const returnparams = event.data?.split("?info=")[1].replace("{", "").replace("}", "");
            // {CID:425e6472-9c44-e511-a87a-4851b7b88ee9,SUCCESS:true,UID:8bb0366b-ca89-4339-a900-521ee1e82398}
            const cid = returnparams.split(",")[0].replace("CID:", "");
            const euid = returnparams.split(",")[1].replace("EUID:", "");
            const success = returnparams.split(",")[2].replace("SUCCESS:", "");
            const uid = returnparams.split(",")[3].replace("UID:", "");

            const newcards = await getCards()

            let cardExist = false;
            if (newcards) {
                for (let i = 0, j = newcards.length; i < j; i++) {
                    if (cid === newcards[i].cardnumber) {
                        cardExist = true;
                        break;
                    }
                }
            }

            if (!cardExist) {
                // console.log("CARD ADD PROCESS - CARD NOT EXIST")
                // console.log("SUCCESS : ", success)
                if (success === "true") {
                    // console.log("CARD ADD PROCESS - VISA ENROLLMENT SUCCESS")
                    try {
                        const userdata = await offerwallapi.post("/api/cards/getvisacarduser", {
                            tenantid: tenant.tenantid,
                            clientid: client.clientid,
                            userkey: client.externaluserid
                        });

                        try {
                            //const clientobj = { ...client }
                            //clientobj.visaclientid = uid;
                            //clientobj.isenrolled = 1;
                            //clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });
                            const cardobj = {};
                            const carddetails = userdata.data.enrollmentRecord.cardDetails;
                            cardobj.isdefault = (carddetails.length === 1) ? 1 : 0;
                            cardobj.clientid = client.clientid;
                            cardobj.tenantid = tenant.tenantid;
                            cardobj.UserKey = client.externaluserid;
                            cardobj.UserId = uid;
                            cardobj.CardId = cid;

                            for (let i = 0, j = carddetails.length; i < j; i++) {
                                if (cid === carddetails[i].CardId) {
                                    cardobj.ReferenceId = carddetails[i].ServiceKey;
                                    cardobj.CardLast4 = carddetails[i].CardLast4;
                                    break;
                                }
                            }
                            // console.log("CARD ADD PROCESS - CARD OBJECT : ", cardobj)
                            const clientobject = await offerwallapi.post("/api/cards/cardcreate", cardobj);

                            //clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobject });
                            // console.log("CARD ADD PROCESS - CARD ADDED OT OW")
                            setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);
                            // console.log("CARD ADD PROCESS - SUCCESS : ", language?.success_res.success_l4)
                            setMessage(language?.success_res.success_l4);
                            setTimeout(() => { setLoader(false) }, 2000);
                            clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobject.data });

                            //getCards();

                        } catch (err) {
                            console.log("OW-ERR-ADDCARD 1: ", err)
                            setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                            setMessage(language?.error_res.error_l10);
                            setTimeout(() => { setLoader(false) }, 2000);
                        }
                    } catch (err) {
                        console.log("OW-ERR-ADDCARD 2: ", err)
                        setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                        setMessage(language?.error_res.error_l10);
                        setTimeout(() => { setLoader(false) }, 2000);
                    }


                } else {
                    // console.log("MINIXPRESS-ERR-ADDCARD : ", returnparams)
                    setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                    setMessage(language?.error_res.error_l10);
                    setTimeout(() => { setLoader(false) }, 2000);
                }
                //check for SUCCESS=TRUE to get CardId and UserId and perform exception handling otherwise, etc..
            } else {
                // console.log("OW-ERR-CARDEXIST : ", cardExist)
                setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                setMessage(language?.error_res.error_l15);
                setTimeout(() => { setLoader(false) }, 2000);
            }
            // window.addEventListener("message", CardAddProcess, false);
        }
    }

    const GetVisaParams = async () => {
        const encryptparams = await offerwallapi.get("/api/cards/enrolleevisaparams", {
            params: {
                tenantid: tenant.tenantid,
                clientid: client.clientid
            }
        });
        return encryptparams.data;
    }

    const closeConfirmModal = () => {
        setShowConfirmModal(false);
    }
    const confirmModal = (type, cardinfo) => {
        if (type === "Remove Card") {
            setSelectedCard(cardinfo);
            setConfirmMessage(language?.card_enroll_labels.card_l10);
        } else if (type === "Unenroll") {
            setConfirmMessage(language?.card_enroll_labels.card_l11);
        }
        setConfirmType(type);
        setShowConfirmModal(true);
    }

    const confirmAction = () => {
        closeConfirmModal();
        if (confirmType === "Unenroll") {
            unenrollClient();
        } else if (confirmType === "Remove Card") {
            removeCard();
        }
    }

    const unenrollClient = async () => {
        try {
            setMessage("");
            setLoader(true);
            const encryptparams = await GetVisaParams();
            // console.log("UNENROLL - GET VISA PARAMS")
            const info = { UserId: client.visaclientid };


            const key = CryptoJS.enc.Utf8.parse(encryptparams.visa_key);
            const iv = CryptoJS.enc.Utf8.parse(encryptparams.visa_iv);


            const encryptedInfo = CryptoJS.AES.encrypt(JSON.stringify(info), key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }).toString();

            const encryptedObj = `?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_direct)}&info2={"V":${encryptparams.cert_version}}`;

            const res = await axios.delete(encryptparams.visa_api + "/Enrollees" + encryptedObj);
            // console.log("UNENROLL - MINI XPRESS DELETE CALL", res)
            if (res.data.Success) {
                // console.log("UNENROLL - MINI XPRESS SUCCESS")
                try {

                    // const clientobj = { ...client }
                    // clientobj.visaclientid = "";
                    // clientobj.isenrolled = 0;
                    // clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });

                    const unenrollObj = {
                        clientid: client.clientid,
                        tenantid: tenant.tenantid
                    };
                    const clientobj = await offerwallapi.put("/api/cards/unenrollclient", unenrollObj);
                    // console.log("OW-SUCC-UNENROLL", language?.success_res.success_l5)
                    setMessage(language?.success_res.success_l5);
                    setTimeout(() => { setLoader(false) }, 2000);
                    clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj.data });

                    //getCards();
                } catch (err) {
                    console.log("OW-ERR-UNENROLL 1: ", err)
                    setMessage(language?.error_res.error_l13);
                    setTimeout(() => { setLoader(false) }, 2000);
                }
            } else {
                // console.log("MINIXPRESS-ERR-UNENROLL : ", res)
                setMessage(language?.error_res.error_l13);
                setTimeout(() => { setLoader(false) }, 2000);
            }
        } catch (error) {
            console.log("OW-ERR-UNENROLL 2: ", error)
            setMessage(language?.error_res.error_l13);
            setTimeout(() => { setLoader(false) }, 2000);
        }

        setShowConfirmModal(false);
    }

    const removeCard = async () => {
        // console.log("REMOVE CARD - IN")
        try {
            setMessage("");
            setLoader(true);
            const encryptparams = await GetVisaParams();

            const info = { UserId: client.visaclientid, CardId: selectedCard.cardnumber };


            const key = CryptoJS.enc.Utf8.parse(encryptparams.visa_key);
            const iv = CryptoJS.enc.Utf8.parse(encryptparams.visa_iv);

            const encryptedInfo = CryptoJS.AES.encrypt(JSON.stringify(info), key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }).toString();

            const encryptedObj = `?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_direct)}&info2={"V":${encryptparams.cert_version}}`;


            const res = await axios.delete(encryptparams.visa_api + "/Cards" + encryptedObj);
            // console.log("REMOVE CARD - VISA CALL : ", res)
            if (res.data.Success) {
                try {
                    const deleteObj = {
                        cardid: selectedCard.cardnumber,
                        clientid: client.clientid,
                        tenantid: tenant.tenantid,
                        id: selectedCard.id
                    };
                    const clientobj = await offerwallapi.delete("/api/cards/carddelete", {
                        params: deleteObj
                    });
                    // console.log("REMOVE CARD - OW CARD DELETED : ", language?.success_res.success_l3)
                    setMessage(language?.success_res.success_l3);
                    await setTimeout(() => { setLoader(false) }, 2000);
                    clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj.data });


                    //getCards();
                } catch (err) {
                    console.log("OW-ERR-DELETECARD 1 : ", err)
                    setMessage(language?.error_res.error_l12);
                }
            } else {
                // console.log("MINIXPRESS-ERR-UNENROLL : ", res)
                setMessage(language?.error_res.error_l12);
            }
            setTimeout(() => { setLoader(false) }, 2000);

        } catch (error) {
            console.log("OW-ERR-UNENROLL 2 : ", error)
            setMessage(language?.error_res.error_l12);
            setTimeout(() => { setLoader(false) }, 2000);
        }
        setShowConfirmModal(false);
    }


    const getCards = async () => {
        try {
            carddispatch({ type: "CARD_START" });
            const cards = await offerwallapi.get("/api/cards/cardgetall", {
                params: {
                    clientid: client.clientid,
                    tenantid: tenant.tenantid
                }
            });
            carddispatch({ type: "CARD_SUCCESS", payload: cards.data });
            return cards.data; // setMyCards(cards.data);
        } catch (error) {
            console.log("OW-ERR-GETCARDS : ", error)
            carddispatch({ type: "CARD_FAIL", payload: error });
        }

        // setCardList(cards.data);
    }

    const setDefaultCard = async (cardinfo) => {

        setMessage("");
        setLoader(true);
        try {

            const clientobj = await offerwallapi.put("/api/cards/cardsetdefault", {
                clientid: client.clientid,
                tenantid: tenant.tenantid,
                cardnumber: cardinfo.cardnumber,
                id: cardinfo.id
            });
            // console.log("DEFAULT CARD")
            // setMessage(language?.success_res.success_l3);
            // await setTimeout(() => { setLoader(false) }, 2000);
            clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj.data });
            // clientdispatch({ type: "CLIENT_SUCCESS", payload: client });
            setLoader(false);
        } catch (error) {
            console.log("OW-ERR-DEFAULTCARD: ", error)
            setLoader(false);
        }
    }

    const emailSubscriptionConfirmModal = (e) => {
        if (!chkEmailSubscription.current.checked) {
            setShowEmailSubscriptionConfirmModal(true);
        } else {
            emailSubscription(1);
        }
    }
    const closeEmailSubscriptionConfirmModal = () => {
        setShowEmailSubscriptionConfirmModal(false);
    }
    const emailSubscription = async (isEmailSubscribed) => {
        setMessage("");
        setLoader(true);
        try {

            const clientobj = await offerwallapi.put("/api/cards/emailsubscription", {
                clientid: client.clientid,
                tenantid: tenant.tenantid,
                externaluserid: client.externaluserid,
                isemailsubscribed: isEmailSubscribed
            });

            //setMessage();
            // await setTimeout(() => { setLoader(false) }, 2000);
            //clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj.data });
            // clientdispatch({ type: "CLIENT_SUCCESS", payload: client });
            setShowEmailSubscriptionConfirmModal(false);
            setTimeout(() => { setLoader(false) }, 2000);
        } catch (error) {
            console.log("OW-ERR-EMAILSUB: ", error)
            setTimeout(() => { setLoader(false) }, 2000);
        }
    }

    const getEmailSubscription = async () => {

        try {
            // console.log('im in')
            const clientobj = await offerwallapi.post("/api/cards/emailgetsubscription", {
                clientid: client.clientid,
                tenantid: tenant.tenantid,
                externaluserid: client.externaluserid,
            });

            // console.log('clientobj', clientobj)
            // chkEmailSubscription.current.checked = clientobj.isEmailSubscribed;
            chkEmailSubscription.current.checked = clientobj;

        } catch (error) {
            console.log("OW-ERR-GETEMAILSUB: ", error)

        }
    }


    useEffect(() => {
        const onload = async () => {
            setMessage("");

            if (tenant && !CheckAuth()) {
                navigate(`/${tenant.tenanturlname}`);
            } else {
                try {
                    if (tenant && client) {
                        setIsLoaded(true)
                        setLoader(true);
                        const encryptparams = await GetVisaParams();
                        const info = `{EUID:${client.externaluserid},PC:${tenant.tenantpromocode},EM:${encryptparams.email}}`;

                        const key = CryptoJS.enc.Utf8.parse(encryptparams.visa_key);
                        const iv = CryptoJS.enc.Utf8.parse(encryptparams.visa_iv);

                        const encryptedInfo = CryptoJS.AES.encrypt(info, key, {
                            iv: iv,
                            mode: CryptoJS.mode.CBC,
                            padding: CryptoJS.pad.Pkcs7,
                        }).toString();
                        setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);
                        //getCards();
                        // setMessage("");
                        setLoader(false);
                    }
                } catch (error) {
                    console.log("OW-ERR-LOAD : ", error)
                    setMessage("");
                    setLoader(false);
                }
            }
        }
        // console.log("IS LOADED : ", isLoaded)
        if (!isLoaded) {
            onload();
            // getEmailSubscription()
        };

    }, [tenant, client, isLoaded]);


    // const frame = document.getElementById("frame");
    // const startPolling = () => {
    //     console.log("AAAAAAAAA : ", frame.querySelector("#interstitial-wrapper"));
    //     if (frame.querySelector("#sub-frame-error")) {
    //         console.log('react is ready')
    //         return
    //     }
    //     //setTimeout(startPolling, 1000)
    // }

    return <div className="container">
        <Link to={`/${tenant?.tenanturlname}`} className="text-dark" style={{ fontSize: "30px" }}>
            <i className="bi bi-chevron-left"></i>
        </Link>

        {
            (tenant?.tenantmaxcardcount == card?.length) ?
                <div className="mb-3">
                    {language?.card_enroll_labels.card_l15}
                </div>
                :
                <div>
                    <h3 className="mt-3">{language?.card_enroll_labels.card_l1}</h3>
                    <div className="row">
                        <div className="mb-3">
                            {language?.card_enroll_labels.card_l2}
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-8">
                                    {/* <Form.Control type="text" placeholder={language?.card_enroll_labels.card_l3} autoFocus
                            ref={txtVisaCardNumber} onKeyUp={validateCard} className="d-inline" style={{ boxShadow: "none" }} /> */}
                                    {/* {(client?.isenrolled) ? <h5>{language?.card_enroll_labels.card_l14}</h5> : <h5>{language?.card_enroll_labels.card_l13}</h5>} */}
                                    {/* <iframe ref={ifrmMini} src={expressURL} title="VISA" style={{ width: "435px", height: "240px" }}></iframe> */}
                                    {(!client?.isenrolled) ? <div style={{ width: "435px" }}>
                                        <Link to={`/${tenant?.tenanturlname}`} className="text-dark  d-block mt-2 float-end">
                                            {language?.card_enroll_labels.card_l4}
                                        </Link>
                                    </div> : ""}
                                    {/* onLoad={onCardSuccess}<Button variant="primary" className="ow-btn-primary" onClick={returnURL} >aaa</Button> */}

                                    {/* <Button variant="primary" className="ow-btn-primary" onClick={getValues} >bbb</Button> */}


                                </div>
                                {/* <div className="col-md-6 col-lg-4">
                        {(client?.isenrolled) ?
                            <div>
                                { <Button variant="primary" className="ow-btn-primary d-inline w-100 mt-2 mt-sm-2 mt-md-0" onClick={addCard} disabled={isDisabled}>{language?.card_enroll_labels.card_b3}</Button> }
                                { <Button variant="secondary-outline" className="d-inline w-100 mt-2 mt-sm-2 mt-md-0"
                                    onClick={() => confirmModal("Unenroll")}>{language?.card_enroll_labels.card_b2}</Button> }
                            </div>
                            :
                            <div>
                                { <Button variant="primary" className="ow-btn-primary d-inline w-100 mt-2 mt-sm-2 mt-md-0" onClick={enrollClient} disabled={isDisabled}>{language?.card_enroll_labels.card_b1}</Button> }
                                { <Link to={`/${tenant?.tenanturlname}`} className="text-dark ms-4 d-block mt-2">
                                    {language?.card_enroll_labels.card_l4}
                                </Link> }
                            </div>
                        }
                    </div> */}
                            </div>
                        </div>
                        <p className="small mt-3">*{language?.card_enroll_labels.card_l5.replace('[MAX_CARD_COUNT]', tenant.tenantmaxcardcount)}</p>
                    </div>
                </div>
        }


        <hr />
        <h4 className="mt-5 mb-3 d-inline">{language?.card_enroll_labels.card_l6}</h4>
        {(client?.isenrolled) ? <div className="d-inline"><Button variant="outline-secondary" className="d-inline ms-4"
            onClick={() => confirmModal("Unenroll")}
            style={{ marginTop: "-5px" }}>{language?.card_enroll_labels.card_b2}</Button>
            <Link to={`/${tenant?.tenanturlname}`} className="text-dark  d-inline ms-4 mt-2">
                {language?.card_enroll_labels.card_l4}
            </Link></div> : ""}

        {
            (card?.length > 0) ?
                <Row xs={1} md={3} className="g-4 mt-3">
                    {card.map((carditem, i) => (
                        <Col key={i}>
                            <Card className={`h-100 ${(carditem.isdefault === 1) ? "border-success" : ""}`}>
                                <Card.Body className={(carditem.isdefault === 1) ? "border-success border-start border-5" : ""}>
                                    {/* {(carditem.isdefault === 1) ?
                                        <i className="bi bi-check-square-fill float-end ow-primary h4"></i> :
                                        <i className="bi bi-square float-end h4" onClick={() => setDefaultCard({ cardnumber: carditem.cardnumber, id: carditem.id })}></i>} */}

                                    {
                                        (carditem.isdefault === 1) ? <Badge bg="success" className="position-absolute" style={{ top: "5px", right: "5px" }}>{language?.card_enroll_labels.card_l12}</Badge> : ""
                                    }
                                    <Card.Title>xxxx xxxx xxxx {carditem.last4digits}</Card.Title>
                                    <div className="clearfix">

                                        {(carditem.isdefault === 0) ?
                                            <div>
                                                <Button variant="outline-danger" className="float-end m-1"
                                                    onClick={() => confirmModal("Remove Card", { cardnumber: carditem.cardnumber, id: carditem.id })}>
                                                    <i className="bi bi-trash"></i></Button>
                                                <Button variant="outline-success" className="float-end m-1"
                                                    onClick={() => setDefaultCard({ cardnumber: carditem.cardnumber, id: carditem.id })}>
                                                    {language?.card_enroll_labels.card_b5}</Button>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                :
                <div className="mt-5 mb-5 text-center">
                    {(client?.isenrolled) ? <div><h5>{language?.card_enroll_labels.card_l7}</h5></div> :
                        <div>
                            <h5>{language?.card_enroll_labels.card_l8}</h5>
                        </div>
                    }
                </div>
        }

        <hr />

        <h4 className="mt-5 mb-3 d-inline">Offers Email Subscription</h4>
        <div className="form-check form-switch" style={{ display: "inline-flex", marginLeft: "15px" }}>
            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                ref={chkEmailSubscription} onChange={emailSubscriptionConfirmModal} />
            {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
        </div>
        <div>
            Receive promotions and exclusive cashback offers straight to your inbox without missing out the opportunity to save more!
        </div>

        <Modal show={showConfirmModal} onHide={closeConfirmModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{language?.card_enroll_labels.card_l9}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{confirmMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeConfirmModal}>
                    {language?.static_labels.no}
                </Button>
                <Button variant="primary" onClick={confirmAction}>
                    {language?.static_labels.yes}
                </Button>
            </Modal.Footer>
        </Modal>


        <Modal show={showEmailSubscriptionConfirmModal}
            onHide={closeEmailSubscriptionConfirmModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to unsubscribe from receiving offers to your email?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeEmailSubscriptionConfirmModal}>
                    {language?.static_labels.no}
                </Button>
                <Button variant="primary" onClick={() => emailSubscription(0)}>
                    {language?.static_labels.yes}
                </Button>
            </Modal.Footer>
        </Modal>

        {
            (loader) ? <div className="ow-full-overlay">
                <div className="position-absolute ow-content-center text-center">
                    <Spinner animation="border" backdrop="static" variant="light" />
                    <h3 className="text-light">{message}</h3>
                </div>
            </div>
                : ""
        }
    </div>
}